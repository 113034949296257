@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

// about
.about {
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
    
    .intro {
        max-width: calc(600px - 1em);
        margin-bottom: 2em;
        margin-right: 1em;

        .intro-body {
            p {
                margin-bottom: $pageMargin;
            }
        }
    }

    .portrait {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        color: black;
        align-items: flex-start;
        max-width: 240px;
        height: auto;
        border-radius: 999px;
        image-rendering: pixelated;
        image-rendering: crisp-edges;
        background: white;
        margin-bottom: $pageMargin;
    }

    .ssb-wrapper {
        //max-width: calc(1000px - 1em);
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .ssb {
            border-radius: $bRadius;
            background: $ssbBGC;
            box-shadow: $ssbBGB;
            margin: 0.5em 0;
            padding: 1em;
            
            .ssb-title {
                font-weight: $bodyFontBold;
                font-size: 1.6rem;
            }
        }
    }
    @media screen and (min-width: $bp1) {
        .ssb-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 1em 1em;

            .ssb {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: $bp2) {
        .ssb-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 1em 1em;
            .ssb {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: $bp3) {
        .intro {
            margin-left: 10%;

        }
        .skillset {
            margin-left: 10%;
            .ssb-wrapper {
                max-width: calc(90vw - 1em);
            }
        }
    }

    @media screen and (min-width: $bp4) {

            .intro {
                max-width: calc(40vw - 1em); 
            }

            .ssb-wrapper {
                max-width: calc(70vw - 1em);
            }

        
    }
}