@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

.hamburger {    
    transition: opacity 1.3s;
}

nav {
    visibility: hidden;
    position: fixed;
    top: 0; left: 0;
    user-select: none;
    z-index: -2;
    font-family: $bodyFont;

    &.show {
        z-index: 999;
        visibility: visible;
        transition: $menuDur visibility ease-in-out;
    }
 
    .navlinks {
        visibility: hidden;
        display: flex;
        justify-content: space-around;
        margin: 0;
        padding: 3em 3em 0em 3em;
        flex-direction: column;
        list-style: none;
        opacity: 0;
        transition: none;
    
        &.show {
            visibility: visible;
            opacity: 1;
            transition: $menuDur opacity ease-in-out, $menuDur visibility ease-in-out;
        }
        
        li {
            margin-bottom: 1em;
            font-size: 2em;
            color: $mob-nav-white;

            &.mob-blurb {
                margin-bottom: 1rem;
            }
            
            a {
                color: $mob-nav-white;

                &.active {
                    color: $hl-heading;
                    text-decoration: none;
                }
            }
        }
    }
}

.nav-backdrop {
    visibility: hidden;
    position: fixed;
    top:0; left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.95);
    opacity: 0;
    transition: $menuDur opacity ease-in-out, $menuDur visibility ease-in-out;

    &.show {
        visibility: visible;
        opacity: 1;
        transition: $menuDur opacity ease-in-out, $menuDur visibility ease-in-out;
    }
}

.burger-open {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
}

.no-scroll {
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    .hamburger, .nav-backdrop {display: none;}
    .menu { z-index: 9999;}
    nav {
        display: flex;
        visibility: visible;
        position: relative;
        min-height: 82px;

        .navlinks {
            visibility: visible;
            flex-direction: row;
            justify-content: space-between;
            opacity: 1;
            transition: none;
            padding: 0;
            margin-right: $pageMargin;
            max-width: 100%;         

            li {
                display: flex;
                max-width: 100%;
                align-items: center;
                margin-bottom: 0;
                
                &.mob-home, &.mob-blurb { display: none; }
                a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    font-size: $bodyFontSize;
                    font-weight: $bodyFontMed;
                    text-decoration: none;
                    color: $base_txt;
                    padding: 0 $pageMargin/2;

                    transition: color 0.3s ease-in-out;
                    
                    &:hover {
                        color: $nav-hover;
                    }
                }
            }

        }
    }
    
}