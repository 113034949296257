// not found
.notf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
}