@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

// home
.home {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
    
    .intro {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: calc(600px - 1em);

        h1 {
            font-size: $fontSizeYuj-mob;
        }

        .drinkSpan {
            color: $base_txt;
            font-weight: $bodyFontBold;
            transition: opacity 0.3s ease-in-out;
        }
    }

    .posi-image {
        display: none;
    }

    @media screen and (min-width: $bp2) {
        .intro {
            width: 100%;
            margin-left: 20%;
        }
    }
}