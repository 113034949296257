@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

html, body {
    width: 100%;
    height: 100%;
    position: relative;
}
body {
    background: $base_bgc no-repeat fixed;
    color: $base_txt;
    font-family: $bodyFont;
    font-weight: $bodyFontReg;
    font-size: $bodyFontSize;
    margin: 0;
    padding: 0;
    position: relative;

    width: 100%;
    min-height: 100%;

    main {
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1;
        //margin-top: $pageMargin * 4;
    }
}

#root {
    .wrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.content {
    margin: 0 $pageMargin $pageMargin*2 $pageMargin;

    h1, h2, h3, h4 { padding: 0;
        margin: 0; }

    h1, h2, h3 { font-size: $logoFontSize;
        font-weight: $bodyFontBlack; }

    h2 { font-weight: $bodyFontMed; }

    p { padding: 0;
        margin: 0; }
    
    .hlh {
        
        font-weight: $bodyFontBold;
        font-size: 1.4em;
    }

    .codenm {
        font-family: monospace;
    }

}

.content, footer {
    a {
        color: $link-color;
        font-weight: $bodyFontBold;
        text-decoration: none;
        padding: 0;
        border-bottom: 2px solid $link-color;
        transition: padding-bottom 0.3s ease-in-out,
                    color 0.3s ease-in-out,
                    border-color 0.3s ease-in-out;

         &:hover {
            padding-bottom: 0.15em;
            border-color: white;
            color: $btn-hover;
        }
    }
}

#splash {
    margin: 0;
    padding: 0;
}

//canvas 
#canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;  
    z-index: -1;
}

//general page styles
.home .intro, .about .intro, .works .intro, .ctct .intro, .notf .intro {
    & > h1 {
        font-size: $fontSizeYuj-mob;
        line-height: 1.2em;
        padding: 1em 0;
    }

    @media screen and (min-width: $bp4) {
        .home .intro h1, .about .intro h1, .works .intro h1, .ctct .intro h1, .notf .intro h1 {
            font-size: $fontSizeYuj;
        }
    }
}

