@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

//single
.single-work {
    
    h1 {
        font-size: $fontSizeMed-mob;
        font-weight: $bodyFontBlack;
        margin: $pageMargin 0;
    }

    .single-p {
        margin-bottom: $pageMargin * 2;

        figure.inner-img {
            margin-bottom: $pageMargin * 2;
        }

        p {
            margin-bottom: $pageMargin * 2;
        }
    }

    &.port {
        margin-top: 4em;
    }

    .vproj-wrapper {
        clear:both;
    }

}

@media screen and (min-width: $bp2) {
    .single-work {
        margin: 0 8em;

        .pb-splash {
            max-height: 60vh;
            object-position: 20% 40%;
            object-fit: cover;
        }

        .rt-splash {
            max-height: 60vh;
            object-position: top;
            object-fit: cover;
        }

        .capst-splash {
            max-height: 60vh;
            object-position: center;
            object-fit: cover;
        }

        .capst-process {
            width: 100%;
            max-height: 240px;
            object-position: center;
            object-fit: cover;
        }
    }
}

@media screen and (min-width: 1080px) {
    .single-work {
        figure.inner-img, .inner-img {
            position: relative;
            max-width: 30vw;
            margin-right: $pageMargin * 2;     
            float: left;
            clear:both;
        }
    }

    .port {

        figure.inner-img, .inner-img {
            max-width: 100%;
            float: none;
            
        }
    }
}

@media screen and (min-width: $bp3) {
    .single-work {
        margin: 0 12em;

        figure.inner-img, .inner-img {
            max-width: 18vw;
        }
    }

    .rltk {
        .single-work {
            figure.inner-img, .inner-img {
                max-width: 30vw;
            }
        }
    }

}

@media screen and (min-width: $bp4) {
    .single-work {
        margin: 0 20em;

        
        figure.inner-img, .inner-img {
            max-width: 30vw;
        }
    }  

}

@media screen and (min-width: 2000px) {
    .single-work {
        margin: 0 24vw;
    }

}