@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;


    .about {
        .skillset-title {
            font-size: $fontSizeMed-mob;
            font-weight: $bodyFontBlack;
            margin-bottom: $pageMargin;
        }
        .ssb-wrapper {
            .ssb {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .ssb-icon {
                    width: 72px;
                    height: 72px;
                    margin-bottom:1em;
                }

                .ssb-desc {
                    bottom: 0;
                }
            }
        }
    }
