@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    font-family: $bodyFont;
    background-color: transparent;
    position: static;
    z-index: 910;
    width: 100%;

    min-height: 82px;
    top: 0; left: 0;

    transition: 0.4s background-color ease-in-out, top 0.15s ease-in-out;

    .logo {
        display: flex;
        align-items:center;
        min-height: inherit;
        letter-spacing: -0.02em;
        font-size: $logoFontSize;
        font-weight: $bodyFontMed;
        top: 0; left: 0;

        a {
            display: flex;
            align-items:center;
            min-height: inherit;
            color: $base_txt;
            text-decoration: none;
            padding: 0 $pageMargin;
        }
    }

    &.wbg {
        background: $base_bgc;
    }

    &.bgn {
        top: -100px;
        transition: top 0.3s ease-in-out;
    }
}

.hasSplash {
    position: fixed;
    left: 0; top: 0;
}