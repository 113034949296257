@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

// contact page
.ctct {
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
    
    .info section {
        margin-bottom: 2em;
    }

    .info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: calc(600px - 1em);
       
        a {
        color: $base-txt;
        text-decoration: none;
        transition: color 0.3s ease-in-out;
        border: 0;

            &.hlt {
                color: $hl-heading;
            }
    
            &:hover {
                color: $link-color;
            }
        }
    }
}

@media screen and (min-width: $bp1) {
    .ctct {
        .intro, .info {
            margin-left: 10%;
        }
    }
}

@media screen and (min-width: $bp2) {
    .ctct {
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        .intro, .info {
            margin-left: 20%;
        }
    }
}