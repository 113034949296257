
$bodyFontSize: 1.2rem;
$logoFontSize: 1.2rem;

$fontSizeYuj-mob: 2.4rem;
$fontSizeYuj: 4.8rem;

$fontSizeMed-mob: 1.6rem;
$fontSizeMed: 2.2rem;


//page margin
$pageMargin: 1.4rem;
$mobPageMargin: 16px;

//anims
$menuDur: 0.42s;

// element styles
$bRadius: 12px;

//bpoints
$bp1: 480px;
$bp2: 768px;
$bp3: 1280px;
$bp4: 1600px;