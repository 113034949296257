//background:
$base_bgc: linear-gradient(33deg, rgb(10, 10, 19), rgb(30, 30, 73));
$base_txt: rgb(238, 238, 238);

$dShadow: rgba(23, 23, 23, 0.4);
$ssbBGC: rgba(35, 40, 68, 0.8);
$ssbBGB: 0px 0px 32px 1px $dShadow;

//text colors:
$mob-nav-white: white;
$link-color: rgba(118, 148, 255, 0.8);
$link-hover: white;
$hl-heading: rgb(118, 148, 255);
$btn-color: rgb(16, 100, 255);
$btn-hover: rgb(255, 255, 255);

$nav-hover: rgb(169, 154, 255);

//element colors

//burger
$burgDefCol: white;
$burgOpenCol: white;
