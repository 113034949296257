@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: $bodyFont;
    margin: {
        top: $pageMargin;
        left: $pageMargin;
        right: $pageMargin;
        bottom: $pageMargin;
    }

    ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-right: $pageMargin;

            &:last-child {
                margin: 0;
            }
        }
    }
}