@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

.content {
    .vproject-btn, .cta-btn {
        margin:$pageMargin 0;
        padding: $pageMargin 0;
                                
        a.vproject, a.cta {
            padding: $pageMargin;
            border: solid 1px white;
            border-radius: $bRadius;
            height: 100%;
            background-color: $btn-color;
            color: $base_txt;

            transition: 0.3s background-color ease-in-out;
        
            &:hover {
                color: black;
                background-color: $btn-hover;
            }
        }
    }
}