@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

.workblock-wrapper {
    width: 100%;
    margin-bottom: $pageMargin * 4;

    a {
        border: 0;
    }

    .workblock {        
        margin: 0 0 $pageMargin * 2 0;
        width: 100%;
    
            figure {
                display: flex;
                align-items: center;
                justify-content: space-around;
                background: $mob-nav-white;
                margin: 0;
                height: 320px;
                max-height: 320px;

                &.mark-capst {
                    opacity: 1.0;
                    transition: 0.5s opacity ease-in-out;

                    img {
                        object-fit: cover;
                    }

                    &:hover {
                        opacity: 0.3;
                    }
                }

                &.mark-as {
                    background-color:  rgba(40, 57, 134, 0.3);
                    transition: 0.5s background-color ease-in-out;

                    &:hover {
                        background-color: rgba(40, 57, 134, 1.0);
                    }
                }

                &.mark-rt {
                    background-color: rgba(38, 56, 84, 0.3);
                    transition: 0.5s background-color ease-in-out;

                    &:hover {
                        background-color:rgba(38, 56, 84, 1);
                    }
                }

                &.mark-pbuster {
                    background-color: rgba(63, 87, 63, 0.3);
                    transition: 0.5s background-color ease-in-out;

                    &:hover {
                        background-color: rgba(63, 87, 63, 1);
                    }
                }

                img {
                    max-height: inherit;
                }
            }
            
            .workblock-desc {
                margin: $pageMargin/2 $pageMargin;
                color: $base_txt;
                

                h2 {
                    font-size: $fontSizeMed-mob;
                    font-weight: $bodyFontBold;
                }
                p {
                    font-size: $bodyFontSize;
                    font-weight: $bodyFontReg;
                }
            }
        }

}

@media screen and (min-width: $bp2) {
    .works {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .intro {
            width: 100%;
        }
        
        .workblock-wrapper {
            display: grid;
            grid-template-columns: repeat(2, calc(90vw/2));
            grid-template-rows: repeat(2, 0.65fr 1fr) 0.65fr;
            justify-items: center;
            gap: $pageMargin;
            width: auto;

            figure {
                width: calc(85vw / 2);
            }
          
            a {
                &:first-child {
                    grid-column: 1;
                    grid-row: 1 / 3;
                }
                &:nth-child(2n) {
                    grid-column: 2;
                    grid-row: 2 / 4;
                }
                &:nth-child(3n) {
                    grid-column: 1;
                    grid-row: 3 / 5;
                }
    
                &:nth-child(4n) {
                    grid-column: 2;
                    grid-row: 4 / 6;
                }
            }
        }
    }

    .workblock {
        width: calc(90vw/2);
        margin: 0;
    }    
}

@media screen and (min-width: $bp3) {
    .works {
        .intro {
            width: 100%;
        }    

        .workblock-wrapper {
            grid-template-columns: repeat(2, calc(75vw/2));

            .workblock {
                width: 100%;
                figure {
                    width: calc(36vw);
                    max-width: 540px;
                    min-height: 25vh;
                }
            }
        }
    }
}

@media screen and (min-width: $bp4) {
    .works {
        .workblock-wrapper {
            grid-template-columns: repeat(2, calc(540px + 1.4rem));
        }
    }
}