@use '../vars/colors' as *;
@use '../vars/units' as *;
@use '../vars/font' as *;

//works

.works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4em 0 0 0;
    width: 100%;

    .intro {
        h1 {
            margin-left: $pageMargin;
        }
    }
    // see also workblock.scss under components
    @media screen and (min-width: $bp2) {
        .intro {
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
}

