* {
    box-sizing: border-box;
}

*::before, *::after {
    box-sizing: inherit;
}

img {
    max-width: 100%;
    height: auto;
}

// via @Una on Twitter
.full-width {
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}

figure {
	margin: 0;

	img {
		display: block;
	}
}